// src/stores/userStore.js
import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
    state: () => ({
        token: null,
        isLoggedIn: false,
    }),

    actions: {
        setToken(token) {
            this.token = token;
            this.isLoggedIn = !!token;
            // 将 token 存储到本地
            localStorage.setItem('token', token);
        },

        clearToken() {
            this.token = null;
            this.isLoggedIn = false;
            // 清除本地存储的 token
            localStorage.removeItem('token');
        },
    },

    getters: {
        getToken: (state) => state.token,
    },
});