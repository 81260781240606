<template>


        <el-col :span="6" v-for="item in displayedItems" :key="item.id" style="margin-bottom: 5px;">
            <el-card>
                <template #header>
                    <div class="text_content">{{ item.title }}</div>

                </template>
                <img :src="item.thumb"
                    style="width: 100%" />
            </el-card>

        </el-col>
   


</template>

<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps({
    items: {
        type: Array,
        default: () => [], // 提供一个空数组作为默认值
        required: false,
    },
    limits: {
        //type: Number,
        default: 7, // 提供一个空数组作为默认值
        required: false,
    },
});

const displayedItems = computed(() => {
   // console.log(props);
    return props.items.slice(0, props.limits);
});
</script>

<style>

</style>