// api.js
import axios from 'axios';

const instance = axios.create({
    baseURL: 'http://admin.qhxnkj.cn',
    timeout: 10000,
    headers: { 'Content-Type': 'application/json' }
});

// 添加请求拦截器
instance.interceptors.request.use(function(config) {
    // 在发送请求之前做一些事情，例如添加 token 到 header
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    return config;
}, function(error) {
    // 请求错误时做一些事情
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function(response) {
    // 对响应数据做点什么
    return response;
}, function(error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});

export default instance;