<template>
    <el-row :gutter="20">
        <el-col :span="3">
            <div class="grid-content ep-bg-purple" />
        </el-col>
        <el-col :span="18">

            <div class="common-layout">
                <el-container>
                    <!-----导航------------------->

                    <Header></Header>

                    <!-----导航结束------------------->
                    <el-row :gutter="20" style="margin-top: 30px;">
                        <el-col :span="4">
                            <div class="grid-content ep-bg-purple" />
                        </el-col>
                        <el-col :span="16">
                            <el-select v-model="value" placeholder="Selects" @change="remoteMethod"
                                style="width: 240px">
                                <el-option v-for="item in options" :key="item.value" :label="item.label"
                                    :value="item.value" :disabled="item.disabled" />
                            </el-select>
                            <el-input v-model="search_name" style="width: 240px;margin-left:5px"
                                placeholder="Please input" />

                            <el-button :icon="Search" style="margin-left:5px" @click="searchs()">搜索</el-button>

                        </el-col>
                        <el-col :span="4">
                            <div class="grid-content ep-bg-purple" />
                        </el-col>
                    </el-row>
                    <el-table :data="paginatedItems" style="width: 100%;margin-top:20px" height="540">
                        <el-table-column fixed prop="id" label="编号" />
                        <el-table-column prop="title" label="标题" />
                        <el-table-column prop="price" label="价格" />
                        <el-table-column prop="thumb" label="图片">
                            <template #default="scope">
                                <img :src="scope.row.thumb" alt="图片预览" style="max-width: 100px;">
                            </template>
                        </el-table-column>

                    </el-table>

                    <el-pagination style="margin-top:20px" @size-change="handleSizeChange"
                        @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 40]"
                        :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalItems">
                    </el-pagination>
                    <!--------回到顶部--------->
                    <el-backtop :right="100" :bottom="100" />
                    <!------------结束--------------->
                    <!-----------底部--------------->
                    <el-footer>
                        <Footers></Footers>
                    </el-footer>
                    <!------------------------------>
                </el-container>
            </div>
        </el-col>
        <el-col :span="3">
            <div class="grid-content ep-bg-purple" />
        </el-col>

    </el-row>
</template>

<script setup>
import Header from './Header.vue'
import Banner from './Banner.vue'
import api from '@/api'; // 导入封装的 Axios 实例
import Footers from './Footers.vue'

import { ref, reactive, onMounted, nextTick, computed } from 'vue';
import { Search } from '@element-plus/icons-vue'
const value = ref('')
const search_name = ref('')
const items = ref([]);
const currentPage = ref(1);
const pageSize = ref(10);
const totalItems = ref(0);
let allItems = ref([]);

const options = reactive([
    
    {
        value: '',
        label: '全部',
        //disabled: true,
    },
    {
        value: '苹果',
        label: '苹果'
    }
])


// 在组件挂载时加载数据
onMounted(async () => {
    console.log('开始加载数据了')
    try {
        const response = await fetchData(' ');
        allItems.value = response.data.data || [];
        totalItems.value = allItems.value.length;
        //console.log('开始加载数据了')
        updatePaginatedItems();
    } catch (error) {
        console.error('Error fetching data:', error);
    }
});

const remoteMethod = async (newValue)=>{
    try {
        const response = await fetchData(newValue);
        //console.log(response)
        allItems.value = response.data.data || [];
        totalItems.value = allItems.value.length;
        // console.log('开始加载数据了')
        updatePaginatedItems();
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

const searchs = async () => {
    //console.log('开始加载数据了')
    try {
        const response = await fetchData(search_name.value);
        //console.log(response)
        allItems.value = response.data.data || [];
        totalItems.value = allItems.value.length;
       // console.log('开始加载数据了')
        updatePaginatedItems();
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};


// 定义数据获取函数
const fetchData = async (search_name) => {
    const response = await api.get(`/api/apidata/commodity?search_name=${search_name}`);
    return response;
};

// 更新分页后的数据
const paginatedItems = computed(() => {
    const start = (currentPage.value - 1) * pageSize.value;
    const end = start + pageSize.value;
    return allItems.value.slice(start, end);
});

// 处理每页显示条目数改变的事件
const handleSizeChange = (val) => {
    pageSize.value = val;
    updatePaginatedItems();
};

// 处理当前页数改变的事件
const handleCurrentChange = (val) => {
    currentPage.value = val;
    updatePaginatedItems();
};

// 更新分页后的数据
const updatePaginatedItems = () => {
    items.value = paginatedItems.value;
};
// export default {
//     name: 'Qita',
//     components: { Header, Banner, Footers }

// }
</script>

<style>
.el-carousel__item h3 {
    display: flex;
    color: #475669;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}

.el-divider--horizontal {
    margin: 0px 0px 16px 0px !important;
}

p,
.text_content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>