<template>
    <el-row :gutter="20">
        <el-col :span="3">
            <div class="grid-content ep-bg-purple" />
        </el-col>
        <el-col :span="18">

            <div class="common-layout">
                <el-container>
                    <!-----导航------------------->

                    <Header></Header>

                    <!-----导航结束------------------->

                    <!---------banner------>
                    <el-main>
                        <el-row :gutter="10">
                            <el-col :span="24">

                                <Banner></Banner>
                            </el-col>

                        </el-row>

                    </el-main>

                    <el-row :gutter="20">
                        <el-col :span="4">
                            <el-row class="tac">
                                <el-col :span="24">
                                    <h5 class="mb-2">导航区域</h5>
                                    <!-- <el-menu default-active="2" class="el-menu-vertical-demo" @open="handleOpen"
                                        @close="handleClose"> -->
                                    <el-menu default-active="2" class="el-menu-vertical-demo" @open="handleOpen"
                                        @close="handleClose">

                                        <el-menu-item v-for="items in commoditytypes" :key="items.id" index="items.id">
                                            <el-icon>
                                                <setting />
                                            </el-icon>
                                            <router-link :to="{
                                                name: 'listdate',
                                                query: {
                                                    id: items.id
                                                }
                                            }" active-class="active">
                                                <span>
                                                    {{ items.texts }}
                                                </span>
                                            </router-link>
                                        </el-menu-item>
                                    </el-menu>
                                </el-col>
                            </el-row>
                        </el-col>
                        <router-view></router-view>
                    </el-row>


                    <!--------回到顶部--------->
                    <el-backtop :right="100" :bottom="100" />
                    <!------------结束--------------->
                    <!-----------底部--------------->
                    <el-footer>
                        <Footers></Footers>
                    </el-footer>
                    <!------------------------------>
                </el-container>
            </div>
        </el-col>
        <el-col :span="3">
            <div class="grid-content ep-bg-purple" />
        </el-col>

    </el-row>
</template>

<script setup>
import { onMounted, ref, nextTick, onBeforeMount } from 'vue';
import api from '@/api'; // 导入封装的 Axios 实例
import { useRouter } from 'vue-router';

import Header from './Header.vue'
import Banner from './Banner.vue'

import Footers from './Footers.vue'

import ListData from './ListData.vue';

const commoditytypes = ref([]);
const router = useRouter();

const childRef = ref(null);

onMounted(async () => {

    try {
        const response = await api.get('/api/apidata/commodity_type');

        commoditytypes.value = response.data.data || [];

    } catch (error) {
        console.error('Error fetching data:', error);
    }
   
    //router.push({ name: 'listdate', qyery: { id: 0} })
    // console.log('childRef.value:', childRef.value);
    // if (childRef.value) {
    //     await childRef.value.componentReady;
    //     childRef.value.fetchData();
    // } else {
    //     console.error('子组件实例未准备好');
    // }



});


</script>

<style>
.el-carousel__item h3 {
    display: flex;
    color: #475669;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}

.el-divider--horizontal {
    margin: 0px 0px 16px 0px !important;
}

p,
.text_content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>