<template>
    <el-row :gutter="20">
        <el-col :span="3">
            <div class="grid-content ep-bg-purple" />
        </el-col>
        <el-col :span="18">

            <div class="common-layout">
                <el-container>
                    <!-----导航------------------->

                    <Header></Header>

                    <!-----导航结束------------------->

                    <!---------banner------>
                    <el-main>
                        <el-row :gutter="10">
                            <el-col :span="18">

                                <Banner></Banner>
                            </el-col>

                            <el-col :span="6">
                                <el-card style="max-width: 480px">
                                    <Listp :items="notices" :limits="7"></Listp>
                                    <!-- <template #footer>Footer content</template> -->
                                </el-card>
                            </el-col>
                        </el-row>

                    </el-main>
                    <!------------结束-------->
                    <el-divider />
                    <!----------列表集-------------->
                    <el-row :gutter="10">
                        <Listthumb :items="shops" :limits="8"></Listthumb>

                    </el-row>

                    <!------------结束--------------->
                    <!------------内容列表--------->
                    <el-row :gutter="4">
                        <el-col :span="8" v-for="op in 3" :key="op">
                            <el-card style="max-width: 480px">
                                <template #header>
                                    <div class="card-header">
                                        <span>
                                            <el-breadcrumb separator="/">
                                                <!-- <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item> -->
                                                <el-breadcrumb-item>
                                                    <!-- <a href="/">更多</a> -->
                                                    <router-link :to="{ name: 'list'}">
                                                        <span>
                                                            更多
                                                        </span>
                                                    </router-link>
                                                </el-breadcrumb-item>

                                            </el-breadcrumb>
                                        </span>
                                    </div>
                                </template>
                                <Listp :items="notices" :limits="4"></Listp>
                            </el-card>
                        </el-col>


                    </el-row>

                    <!--------回到顶部--------->
                    <el-backtop :right="100" :bottom="100" />
                    <!------------结束--------------->
                    <!-----------底部--------------->
                    <el-footer>
                        <Footers></Footers>
                    </el-footer>
                    <!------------------------------>
                </el-container>
            </div>
        </el-col>
        <el-col :span="3">
            <div class="grid-content ep-bg-purple" />
        </el-col>


    </el-row>

</template>

<script setup>
import { onMounted, ref } from 'vue';
import api from '@/api'; // 导入封装的 Axios 实例

import Header from './Header.vue'
import Banner from './Banner.vue'
import Listthumb from './Listthumb.vue'
import Listp from './Listp.vue'
import Footers from './Footers.vue'

const notices = ref([]);
const shops = ref([]);
onMounted(async () => {
    try {
        const response = await api.get('/api/apidata/notices');
        
        notices.value = response.data.data || [];
       // console.log(notices.value)

    } catch (error) {
        console.error('Error fetching data:', error);
    }
    try {
        const response = await api.get('/api/apidata/commodity');
        
        shops.value = response.data.data || [];
       // console.log(shops.value)

    } catch (error) {
        console.error('Error fetching data:', error);
    }
});

// export default {
//     name: 'Index',
//     components: { Header, Banner, Listthumb, Listp, Footers }

// }
</script>

<style>
.el-carousel__item h3 {
    display: flex;
    color: #475669;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}

.el-divider--horizontal {
    margin: 0px 0px 16px 0px !important;
}

p,
.text_content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>