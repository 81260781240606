<template>

    <el-header>
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
            <el-menu-item index="index">首页</el-menu-item>
            <el-menu-item index="list">列表</el-menu-item>


            <el-menu-item index="qita">其他</el-menu-item>
            <el-menu-item index="lianxi">联系我们</el-menu-item>
            <el-menu-item :icon="User" @click="login" v-if="userStore.isLoggedIn ==false">登录</el-menu-item>

            <div v-if="userStore.isLoggedIn">
                <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" />

                <el-button :icon="SwitchButton" style="margin-left:20px;margin-bottom: 20px" type="info" plain
                    @click="logout">退出</el-button>
            </div>
        </el-menu>

    </el-header>

</template>

<script setup>
import { SwitchButton,User } from '@element-plus/icons-vue'
// export default {
//     name: 'Header',
//     data() {
//         // return {
//         //     activeIndex: parseInt(localStorage.getItem('activeNavItemIndex')) || 'index'
//         // };
//         // 确保读取的值与你设置的类型相匹配
//         let savedIndex = localStorage.getItem('activeNavItemIndex');
//         return {
//             activeIndex: savedIndex ? savedIndex : 'index', // 使用字符串类型，因为菜单项的索引也是字符串
//         };
//     },
//     methods: {
//         handleSelect(key, keyPath) {
//             // this.$router.push({ name: 'list' });
//             this.activeIndex = key;
//             this.$router.push({ name: key })
//             console.log(this.activeIndex)
//             localStorage.setItem('activeNavItemIndex', key);
//             //console.log(keyPath);
//         }
//     },
//     mounted() {
//         console.log('Active Index:', this.activeIndex);
//     },
// }
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import { useUserStore } from '@/stores/user';

const router = useRouter();

const userStore = useUserStore();

// 使用 ref 创建响应式数据
const activeIndex = ref(localStorage.getItem('activeNavItemIndex') || 'index');

// 确保在 mounted 钩子中读取数据，因为在模板中使用了默认值
onMounted(() => {
    console.log('Active Index:', activeIndex.value);
});

// 方法
const handleSelect = (key, keyPath) => {
    activeIndex.value = key;

    if (key === 'lianxi' && !userStore.isLoggedIn) {
        alert('您需要登录才能访问此页面。');
        //router.push({ name: 'login' }); // 假设你有名为 'login' 的路由
    } else {
        router.push({ name: key });
        localStorage.setItem('activeNavItemIndex', key);
    }
    // 假设你使用的是 Vue Router 4
    // router.push({ name: key });
    
};

const login = () => {
    // 这里应该调用你的后端 API 进行登录验证
    // 为了简化示例，我们假设总是登录成功
    const userStore = useUserStore();
    userStore.setToken('2131313231312324');
};
const logout = () => {
    // 这里应该调用你的后端 API 进行登录验证
    // 为了简化示例，我们假设总是登录成功
    const userStore = useUserStore();
    userStore.clearToken();
    router.push({ name: 'index' });
};
</script>

<style scoped>
.el-menu--horizontal>.el-menu-item {
    width: 18%;
}
</style>