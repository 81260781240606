<template>

    <el-carousel indicator-position="outside">
        <el-carousel-item v-for="bannerItem in banners" :key="bannerItem.id">
            <!-- <h3 text="2xl" justify="center">{{ item }}</h3> -->
            <img :src="$domain+bannerItem.thumb" alt="" style="width: 100%;height: 100%;">
        </el-carousel-item>
    </el-carousel>
</template>

<script setup>
// export default {
//     name:'Banner'
// }
import { onMounted, ref } from 'vue';
import api from '@/api'; // 导入封装的 Axios 实例
const responseData = ref(null);
const banners = ref([]);
onMounted(async () => {
    try {
        const response = await api.get('/api/apidata/banner');
        responseData.value = response.data;
        banners.value = response.data.data || []; 
        
    } catch (error) {
        console.error('Error fetching data:', error);
    }
});
</script>

<style>

</style>