<template>
    <div>
        <p v-for="item in displayedItems" :key="item.id" class="text item" @click="openDialog(item.id)">{{ item.title}}
        </p>


        <el-dialog v-model="dialogVisible" title="详情" style="width: 60%; height: 70%;overflow: scroll;">
            <p style="font-size:29px;font-size:20px">{{ title }}</p>
            <span>
                <div style="padding: 20px; white-space: pre-wrap; word-spacing: 1em; height: 100%; overflow-y: scroll;">
                    {{ content }}
                </div>

            </span>
            <!-- 更多详情 -->
        </el-dialog>
    </div>


</template>

<script setup>
import { defineProps, computed, ref, onMounted } from 'vue';
import api from '@/api'; // 导入封装的 Axios 实例
const props = defineProps({
    items: {
        type: Array,
        default: () => [], // 提供一个空数组作为默认值
        required: false,
    },
    limits: {
        //type: Number,
       default: 7, // 提供一个空数组作为默认值
        required: false,
    },
});

// 使用 ref 来创建响应式状态
const notices = ref([]);
const displayedItems = computed(() => {
    return props.items.slice(0, props.limits);
});


const dialogVisible = ref(false);
const selectedItem = ref({});
const title = ref();
const content = ref();

const openDialog = (item) => {
    
    selectedItem.value = item;
    fetchNotices(item);
    dialogVisible.value = true;
};

onMounted(() => {
   // fetchNotices();
});


const fetchNotices = async (id) => {
    try {
        const response = await api.get(`/api/apidata/noticedetail?id=${id}`);
        notices.value = response.data.data || [];
        title.value = notices.value.title
        content.value = notices.value.content
       
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};
</script>

<style>

p,
.text_content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
span{
    line-height: 34px;
}
</style>