import { createApp } from 'vue';
import App from './App.vue';

import router from './router'; // 引入路由配置

//引入pina状态管理
import { createPinia } from 'pinia';

//状态持久化
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

import { useUserStore } from './stores/user';
// //引入axios
// import axios from 'axios';

//app.config.globalProperties.$http = axios;



import api from './api'; // 导入封装的 Axios 实例

//引入elemenntplus
import ElementPlus from 'element-plus';

import 'element-plus/dist/index.css';

const app = createApp(App);

const pinia = createPinia();


// 使用持久化插件
pinia.use(piniaPluginPersistedstate);

app.use(pinia);

app.use(router); // 注册路由s

app.config.globalProperties.$http = api; // 注册封装的 Axios 实例

// 定义全局域名
app.config.globalProperties.$domain = 'http://admin.qhxnkj.cn';


app.use(ElementPlus);


const userStore = useUserStore();
const token = localStorage.getItem('token');
if (token) {
    userStore.setToken(token);
}


app.mount('#app');