<template>
    <Suspense>
        <el-col :span="18">
            <el-table :data="paginatedItems" stripe style="width: 100%">
                <el-table-column prop="title" label="标题" width="180" />
                <el-table-column prop="price" label="价格" width="180" />
                <el-table-column prop="description" label="简介" />
            </el-table>
            <el-pagination style="margin-top:20px" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="totalItems">
            </el-pagination>
        </el-col>
    </Suspense>
</template>

<script setup>
import {  onMounted, ref, computed ,onBeforeMount} from 'vue';
import { onBeforeRouteUpdate } from 'vue-router';
import api from '@/api'; // 导入封装的 Axios 实例

const ids = ref(null);
const items = ref([]);
const currentPage = ref(1);
const pageSize = ref(4);
const totalItems = ref(0);
let allItems = ref([]);




// 在组件挂载时加载数据
onMounted(async () => {
    console.log('开始加载数据了')
    try {
        const response = await fetchData(ids.value);
        allItems.value = response.data.data || [];
        totalItems.value = allItems.value.length;
        console.log('开始加载数据了')
        updatePaginatedItems();
    } catch (error) {
        console.error('Error fetching data:', error);
    }
});


// 监听路由更新
onBeforeRouteUpdate(async (to, from) => {
    // 更新 ids 值
    ids.value = to.query.id;

    // 当 ids 发生变化时，重新获取数据
    if (ids.value !== from.query.id) {
        try {
            const response = await fetchData(ids.value);
            allItems.value = response.data.data || [];
            totalItems.value = allItems.value.length;
            updatePaginatedItems();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    } else {
        console.log(222)
    }
});
// onBeforeMount(async () => {
//     console.log('挂在之前加载数据')
//     try {
//         const response = await fetchData(ids.value);
//         allItems.value = response.data.data || [];
//         totalItems.value = allItems.value.length;
//         updatePaginatedItems();
//     } catch (error) {
//         console.error('Error fetching data:', error);
//     }
// });

// 定义数据获取函数
const fetchData = async (id) => {
    const response = await api.get(`/api/apidata/commodity?commodity_type_id=${id}`);
    return response;
};

// 更新分页后的数据
const paginatedItems = computed(() => {
    const start = (currentPage.value - 1) * pageSize.value;
    const end = start + pageSize.value;
    return allItems.value.slice(start, end);
});

// 处理每页显示条目数改变的事件
const handleSizeChange = (val) => {
    pageSize.value = val;
    updatePaginatedItems();
};

// 处理当前页数改变的事件
const handleCurrentChange = (val) => {
    currentPage.value = val;
    updatePaginatedItems();
};

// 更新分页后的数据
const updatePaginatedItems = () => {
    items.value = paginatedItems.value;
};
</script>