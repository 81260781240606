<template>
    <el-row :gutter="20" style="line-height: 60px;font-size: 36px;">
        <el-col :span="6" v-for="oo in links" :key="oo">
            <!-- <el-image style="width: 80px; height: 80px;margin-top:15%" :src="url" :fit="fit" /> -->
            <el-link :href="oo.link" target="_blank">{{ oo.title }}</el-link>
        </el-col>

    </el-row>
</template>

<script setup>
// export default {
//     name:'Banner'
// }
import { onMounted, ref } from 'vue';
import api from '@/api'; // 导入封装的 Axios 实例
const responseData = ref(null);
const links = ref([]);
const url =
    'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
onMounted(async () => {
    try {
        const response = await api.get('/api/apidata/links');
        responseData.value = response.data;
        links.value = response.data.data || [];

    } catch (error) {
        console.error('Error fetching data:', error);
    }
});
</script>

<style>

</style>