import { createRouter, createWebHistory } from 'vue-router';
import { useUserStore } from '../stores/user';
//import Home from '../components/HelloWorld.vue';
import Index from '../components/Index.vue';
import List from '../components/List.vue';
import Lianxi from '../components/Lianxi.vue';
import Qita from '../components/Qita.vue';
import ListData from '../components/ListData.vue';

const routes = [{
        path: '/',
        name: 'index',
        component: Index,
    },
    // 添加更多路由
    {
        path: '/list',
        name: 'list',
        component: List,
        children: [{
            path: '/list/listdate',
            name: 'listdate',
            component: ListData,
            meta: { requiresAuth: true },
        }]
    },
    {
        path: '/lianxi',
        name: 'lianxi',
        component: Lianxi,
        //meta: { requiresAuth: true }, // 标记此路由需要认证
    },
    {
        path: '/qita',
        name: 'qita',
        component: Qita,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});
router.beforeEach(async(to, from, next) => {
    const userStore = useUserStore();
    const isAuthenticated = userStore.isLoggedIn;

    // 如果当前导航的路由需要认证，并且用户尚未登录
    if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
        // 重定向到登录页面
        //next({ name: 'login' });
        alert('需要登录')
    } else {
        // 否则继续导航
        next();
    }
});

export default router;