<template>
    <el-row :gutter="20">
        <el-col :span="3">
            <div class="grid-content ep-bg-purple" />
        </el-col>
        <el-col :span="18">

            <div class="common-layout">
                <el-container>
                    <!-----导航------------------->

                    <Header></Header>
                    <!------------结束--------------->

                    <Banner></Banner>
                    <!--------表单开始-------->
                    <el-row :gutter="20">
                        <el-col :span="3">
                            <div class="grid-content ep-bg-purple" />
                        </el-col>
                        <el-col :span="18">
                            <el-form :model="form" :rules="rules" ref="myFormRef" label-width="auto"
                                style="max-width: 600px;margin-top:20px">
                                <el-form-item label="姓名" prop="name">
                                    <el-input v-model="form.name" placeholder="请输入用户名" />
                                </el-form-item>
                                <el-form-item label="电话" prop="phone">
                                    <el-input v-model="form.phone" placeholder="请输入电话" />
                                </el-form-item>
                                <el-form-item label="邮箱" prop="email">
                                    <el-input v-model="form.email" placeholder="请输入邮箱" />
                                </el-form-item>
                                <el-form-item label="性别">
                                    <el-select v-model="form.region">
                                        <el-option label="Zone one" value="男" />
                                        <el-option label="Zone two" value="女" />
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="时间">
                                    <el-col :span="11">
                                        <el-date-picker v-model="form.date1" type="date" placeholder="Pick a date"
                                            style="width: 100%" />
                                    </el-col>
                                    <el-col :span="2" class="text-center">
                                        <span class="text-gray-500">-</span>
                                    </el-col>
                                    <el-col :span="11">
                                        <el-time-picker v-model="form.date2" placeholder="Pick a time"
                                            style="width: 100%" />
                                    </el-col>
                                </el-form-item>
                                <!-- <el-form-item label="Instant delivery">
                                    <el-switch v-model="form.delivery" />
                                </el-form-item> -->
                                <el-form-item label="爱好">
                                    <el-checkbox-group v-model="form.type">
                                        <el-checkbox value="Online activities" name="type">
                                            Online activities
                                        </el-checkbox>
                                        <el-checkbox value="Promotion activities" name="type">
                                            Promotion activities
                                        </el-checkbox>
                                        <el-checkbox value="Offline activities" name="type">
                                            Offline activities
                                        </el-checkbox>
                                        <el-checkbox value="Simple brand exposure" name="type">
                                            Simple brand exposure
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </el-form-item>
                                <el-form-item label="是否展示">
                                    <el-radio-group v-model="form.resource">
                                        <el-radio value="否">否</el-radio>
                                        <el-radio value="是">是</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="说明" prop="desc">
                                    <el-input v-model="form.desc" type="textarea" />
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="onSubmit">提交</el-button>
                                    <el-button>Cancel</el-button>
                                </el-form-item>
                            </el-form>



                        </el-col>

                        <el-col :span="3">
                            <div class="grid-content ep-bg-purple" />
                        </el-col>

                    </el-row>

                    <!--------表单结束-------->
                    <!--------回到顶部--------->
                    <el-backtop :right="100" :bottom="100" />
                    <!------------结束--------------->

                    <!------------结束--------------->
                    <!-----------底部--------------->
                    <el-footer>
                        <Footers></Footers>
                    </el-footer>
                    <!------------------------------>
                </el-container>
            </div>
        </el-col>
        <el-col :span="3">
            <div class="grid-content ep-bg-purple" />
        </el-col>

    </el-row>
</template>

<script setup>
import Header from './Header.vue'
import Footers from './Footers.vue'
import Banner from './Banner.vue'
import { ref, reactive, onMounted, nextTick } from 'vue';
import { ElMessage } from 'element-plus'

// do not use same name with ref
const form = reactive({
    name: '',
    phone:'',
    email:'',
    region: '',
    date1: '',
    date2: '',
    delivery: false,
    type: [],
    resource: '',
    desc: '',
})
const rules = reactive({
    name: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
    desc: [{ required: true, message: '请输入简介', trigger: 'blur' }]
})

// 创建一个 ref 用于稍后获取表单元素的引用
const myFormRef = ref();

const onSubmit = () => {
    myFormRef.value.validate((valid) => {
        if (valid) {
            //提交数据到后台
            console.log(form.name);
            ElMessage({
                message: form,
                type: 'success',
            })
            nextTick().then(() => {
                location.reload();
            });
        } else {
            console.log('验证失败');
        }
    });
}
// 在组件挂载后，你可以访问到 $refs 中的元素
onMounted(() => {
    // 这里可以做进一步的初始化
});

// export default {
//     name: 'Lianxi',
//     components: { Header, Banner, Footers }

// }
</script>

<style>
.el-carousel__item h3 {
    display: flex;
    color: #475669;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}

.el-divider--horizontal {
    margin: 0px 0px 16px 0px !important;
}

p,
.text_content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>